import {homeProspectRegFormSdo} from './sdo';
import {ModalService} from '../../../../../../modules/modal-service';

const errorHandlers = {
  /**
   *
   * @param {Object} err
   * @param {Object} ctx
   */
  defaultErrorHandler(err, ctx) {
    if (err.captcha) {
      return;
    }
    homeProspectRegFormSdo.apiErrors = err.payload;
    ctx.form.apiErrors.validate();
    ModalService.hide();
  }
};

export default {
  defaultErrorHandler: errorHandlers.defaultErrorHandler
};
