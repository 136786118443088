import '@aofl/drawer';
import '@aofl/select';

export default (ctx, html) => html`
  <form id="payment-form-container" name="payment-form" @submit="${(e) => ctx.submitForm(e)}" novalidate>
    <fieldset class="cc-card-payment">
    <div class="input-container ${ctx.form.cardName.valid}">
        <label class="cc-input-label" for="card-name">Cardholder Name</label>
        <div class="input-wrapper">
          <input id="card-name" class="input ${ctx.form.cardName.valid ? '' : 'invalid'}" type="text" name="card-name" @blur="${(e) => ctx.validateCardName(e)}" @input="${(e) => ctx.updateCardName(e)}" value="${ctx.cardName}" autocomplete="cc-name" aria-label="Enter the cardholder name." aria-describedby="card-name-error-message" aria-invalid="${ctx.form.cardName.valid ? 'false' : 'true'}" required>
          ${ctx.form.cardName.valid ? '' : html`
            <label id="card-name-error-message" class="error-message" for="card-name" role="alert" aria-label="Error - Please enter a valid cardholder name. It is a required field and must be filled in.">Please enter a valid cardholder name.</label>
          `}
        </div>
      </div>
      <div class="input-container ${ctx.form.cardNumber.valid}">
        <label class="cc-input-label" for="cc-num">Credit Card Number</label>
        <div class="input-wrapper">
          <input id="cc-num" class="input ${ctx.form.cardNumber.valid ? '' : 'invalid'}" type="tel" name="cc-num" @blur="${(e) => ctx.validateCardNumber(e)}" @input="${(e) => ctx.updateCardNumber(e)}" value="${ctx.cardNumber}" autocomplete="cc-number" aria-label="Enter the credit card number." aria-describedby="cc-num-error-message" aria-invalid="${ctx.form.cardNumber.valid ? 'false' : 'true'}" required>
          ${ctx.form.cardNumber.isRequired.valid ? '' : html`
            <label id="cc-num-error-message" class="error-message" for="cc-num" role="alert" aria-label="Error - Please enter the credit card number. It is a required field and must be filled in.">Please enter the card number.</label>
          `}
          ${ctx.form.cardNumber.isNotZero.valid ? '' : html`
            <label id="cc-num-error-message" class="error-message" for="cc-num" role="alert" aria-label="Error - Please enter a valid credit card number. It is a required field and must be filled in.">Your card number is invalid.</label>
          `}
        </div>
      </div>
      <div id="date-cvv-container" class="input-container ${(ctx.form.expDate.valid && ctx.form.cardCvv.valid) ? '' : 'is-invalid'}">
        <label id="expiration-label">Expiration<span class="u-m-br"></span> Date</label>
        <div id="dropdown-row" class="input-wrapper">
          <div id="date-selector" class="${ctx.form.expDate.valid}">
            <div id="billing-info-month" class="mkt-dropdown">
              <div class="dropdown-wrapper">
                <div class="button-wrapper enteractive tab-outline-blue" @click="${(e) => ctx.toggleMonth(e)}" tabindex="0" aria-label="Use the drop down to enter the month of the credit card expiration date." aria-describedby="exp-error" aria-invalid="${ctx.form.expDate.valid ? 'false' : 'true'}" aria-expanded="${ctx.ariaExpandedMonth}" role="listbox">
                  <div class="display-box">
                    <span>${ctx.cardMonth}</span>
                  </div>
                  <div class="dropdown-arrow">
                    <span>&#9662;</span>
                  </div>
                </div>
                <aofl-drawer id="month-drawer" animate-drawer="false" ?open="${ctx.monthDropdownActive}">
                  <aofl-select-list @change="${(e) => ctx.updateCardMonth(e)}">
                    ${ctx.months.map((month) => html`
                      <aofl-list-option class="enteractive-list-option" value=${month} tabindex="0" role="option">${month}</aofl-list-option>
                    `)}
                  </aofl-select-list>
                </aofl-drawer>
              </div>
            </div>

            <div id="billing-info-year" class="mkt-dropdown">
              <div class="dropdown-wrapper">
                <div class="button-wrapper enteractive tab-outline-blue" @click="${(e) => ctx.toggleYear(e)}" aria-label="Use the drop down to enter the year of the credit card expiration date." tabindex="0" aria-expanded="${ctx.ariaExpandedYear}" role="listbox">
                  <div class="display-box">
                    <span>${ctx.cardYear}</span>
                  </div>
                  <div class="dropdown-arrow">
                    <span>&#9662;</span>
                  </div>
                </div>
                <aofl-drawer id="year-drawer" animate-drawer="false" ?open="${ctx.yearDropdownActive}">
                  <aofl-select-list @change="${(e) => ctx.updateCardYear(e)}">
                    ${ctx.years.map((year) => html`
                      <aofl-list-option class="enteractive-list-option" value=${year} tabindex="0">${year}</aofl-list-option>
                    `)}
                  </aofl-select-list>
                </aofl-drawer>
              </div>
            </div>
          </div>
          ${ctx.form.expDate.isNotExpired.valid ? '' : html`
            <label id="exp-error" class="error-message" for="exp-date" role="alert" aria-label="Error - Your expiration date is invalid. It is a required field and must be filled in.">Your expiration date is invalid.</label>
          `}
        </div>
      </div>
      <div id="cvv-wrapper" class="input-container">
        <label id="cvv-label" for="cvv">CVV</label>
        <div id="cvv-container" class="input-wrapper ${ctx.form.cardCvv.valid}">
          <div class="error-wrapper">
            <input id="cvv" class="input ${ctx.form.cardCvv.valid ? '' : 'invalid'}" type="tel" maxlength="4" name="cvv" @input="${(e) => ctx.updateCardCvv(e)}" value="${ctx.cardCvv}" @blur="${(e) => ctx.validateCardCvv(e)}" aria-label="Enter the CVV of the credit card." aria-describedby="cvv-error-message" aria-invalid="${ctx.form.cardCvv.valid ? 'false' : 'true'}" required>
            ${ctx.form.cardCvv.isRequired.valid ? '' : html`
              <label id="cvv-error-message" class="error-message ${ctx.form.cardCvv.isRequired.valid}" for="cvv" role="alert" aria-label="Error - Please enter your credit card security code. It is a required field and must be filled in.">Please enter the card security code.</label>
            `}
            ${ctx.form.cardCvv.isNotEmoji.valid && ctx.form.cardCvv.isNotZero.valid ? '' : html`
              <label id="cvv-error-message" class="error-message ${ctx.form.cardCvv.isRequired.valid}" for="ccv" role="alert" aria-label="Error - Please enter a valid credit card security code. It is a required field and must be filled in.">Your card security code is invalid.</label>
            `}
          </div>
          <button dom-scope="vQGt9OkB" id="cvv-button" class="button blue tab-outline-blue" type="button" @click="${(e) => ctx.showCvvPopup(e)}">What's This?</button>
        </div>
      </div>
      <div id="zip-code-input-container" class="input-container ${ctx.form.zipCode.valid}">
        <label class="cc-input-label" for="zip-code">Billing Zip/<span class="u-br"></span><span class="u-m-br"></span>Postal Code</label>
        <div class="input-wrapper">
          <input id="zip-code" class="${ctx.form.zipCode.valid ? '' : 'invalid'}" type="tel" name="zip-code" @blur="${(e) => ctx.validateZipCode(e)}" @input="${(e) => ctx.updateZipCode(e)}" value="${ctx.zipCode}" autocomplete="postal-code" aria-label="Enter your Billing Zip or Postal Code. For gift cards or international cards enter 12345." aria-describedby="zipcode-error-message" aria-invalid="${ctx.form.zipCode.valid ? 'false' : 'true'}" required>
          <p class="input-subtext">(Gift/International Cards: please enter 12345)</p>
          ${ctx.form.zipCode.isRequired.valid ? '' : html`
            <label id="zipcode-error-message" class="error-message" for="zip-code" role="alert" aria-label="Error - Please provide Zip or Postal Code. It is a required field and must be filled in.">Please provide Zip or Postal Code.</label>
          `}
          ${ctx.form.zipCode.isNotZero.valid && ctx.form.zipCode.isNotEmoji.valid ? '' : html`
          <label id="zipcode-error-message" class="error-message" for="zip-code" role="alert" aria-label="Error - Please provide Zip or Postal Code. It is a required field and must be filled in.">Zip or Postal Code format you entered is invalid.</label>
          `}
        </div>
      </div>
      <div class="input-container">
        <label class="cc-input-label" for="phone-number">Mobile<span class="u-br"></span><span class="u-m-br"></span> Number<span class="u-br"></span></label>
        <div class="input-wrapper">
          <input id="phone-number" class="input" type="tel" name="phone-number" @input="${(e) => ctx.updatePhoneNumber(e)}" value="${ctx.phoneNumber}" autocomplete="tel" aria-label="Enter your phone number. This is optional, but recommended.">
          <p class="input-subtext">(Optional, but recommended)</p>
        </div>
      </div>
    </fieldset>
    <button dom-scope="SAqx2McY" id="payment-submit-button" type="submit" tabindex="-1" aria-hidden="true">Payment Submit Button</button>
  </form>
`;
