import {cookies, pages} from '../../../../../modules/constants-enumerate';
import '../../../../../modules/link-to-element';
import Cookies from 'js-cookie';

export default (ctx, html) => html`
  <div>
    <p id="first-days-text">Get your first 10 days free!</p>
    <p id="as-low-desc">See our special offer for new members!</p>
    ${Cookies.get(cookies.ELOG) ? html`
    <div class="button-wrapper enteractive tab-outline-blue" role="button" aria-label="Click here to start your 10 day free trial" @click="${(e) => ctx.goToSubscription(e)}" tabindex="0">
      <link-to dom-scope="vC3rOjQc" href="${pages.SUBSCRIPTION}" role="button" id="continue-btn" class="blue button">CLICK HERE TO CONTINUE</link-to>
    </div>`
        : html`
        <form id="prospect-register-form" @submit="${(e) => ctx.submitForm(e)}">
          <p class="italic" id="learn-more-text">Enter your email to learn more!</p>
          <div id="email-container">
            <label for="email" id="email-label">Email</label>
            <input type="email" id="email" class="enteractive tab-outline-blue" tabindex="0" @input="${(e) => ctx.updateEmail(e)}" .value="${ctx.email}" @blur="${(e) => ctx.validateEmail(e)}"/>
            ${ctx.form.email.isRequired.valid ? '' : html`
              <label id="error-message" class="error-message" for="email" role="alert" aria-label="Error - Enter your email address.">Please enter an email address.</label>
            `}
            ${ctx.form.email.isValidEmail.valid ? '' : html`
              <label id="error-message" class="error-message" for="email" role="alert" aria-label="Error - Please enter a valid email address. It is a required field and must be filled in.">Please enter a valid email address.</label>
            `}
            <button dom-scope="BUPTk+8x" type="submit" class="enteractive tab-outline-blue" tabindex="0" aria-label="Submit your email address.">SUBMIT</button>
          </div>

          <div id="checkbox-container">
            <input id="checkbox" type="checkbox" class="checkbox" @change=${(e) => ctx.updateCheckbox(e)} .checked="${ctx.checked}" aria-label="Check to agree to receive emails from Homeschool+." aria-labelledby="checkbox-label" tabindex="-1">
            <label id="checkbox-label" for="checkbox">
              <span id="checkbox-focus" class="tab-outline-blue enteractive-checkbox" for="checkbox" role="checkbox" @click="${(e) => ctx.updateCheckbox(e)}" tabindex="0" aria-label="Check to confirm you agree to receive emails from Homeschool+." aria-describedby="checkbox-error-message"></span>
              <p id="legal-agreement">I agree to receive emails from Homeschool+.</p>
            </label>
            ${ctx.form.checkbox.isChecked.valid ? '' : html`
              <label id="checkbox-error-message" class="error-message" for="checkbox" role="alert" aria-label="Error - You must agree to receive emails from Homeschool+.">You must agree to receive emails from Homeschool+.</label>
            `}
          </div>

          <div>
            <link-to dom-scope="d+TLCnUT" class="underline link" target="_blank" href="${pages.PRIVACY_POLICY}" id="privacy-link" tab-outline-blue>Privacy Policy</link-to>
          </div>

          ${ctx.apiErrors ? html`
            <div class="input-container">
              <label class="error-message" id="api-error">An unknown server error occurred.</label>
            </div>
          ` : ''}
        </form>
      `}
  </div>
`;
