import styles from './template.css';
import template from './template';
import {AoflElement, customElement, property} from '@aofl/element';
import {isValidEmail, isNotEmoji, isNotAllDigits, uncaughtApiErrorsValidator} from '@aoflmkt/form-validators';
import {isRequired, validationMixin} from '@aofl/form-validate';
import {routerInstance} from '@aofl/router';
import {accessibilityMixin} from '../../../../../modules/accessibility-mixin';
import {ApiService} from '../../../../../modules/api-service';
import {clickTrackerMixin} from '../../../../../modules/click-tracker-mixin';
import {aoflProducts, cookies, pages, pixelEvents} from '../../../../../modules/constants-enumerate';
import {PixelService} from '../../../../../modules/pixel-service';
import {ModalService} from '../../../../../modules/modal-service';
import {homePageSdo} from '../sdo';
import {homeProspectRegFormSdo} from './modules/sdo/index';
import apiErrorsEnumerate from './modules/api-errors-enumerate';
import Cookies from 'js-cookie';

const Mixin = validationMixin(clickTrackerMixin(accessibilityMixin(AoflElement)));

/**
 *
 *
 * @class HomeProspectRegForm
 * @extends {Mixin}
 */
@customElement('home-prospect-reg-form')
class HomeProspectRegForm extends Mixin {
  constructor() {
    super();
    this.validators = this.defineValidators();
  }

  static is = 'home-prospect-reg-form'

  @property({type: String, store: homePageSdo, mapState: 'isWdio'})
  isWdio = '';

  @property({type: String, store: homeProspectRegFormSdo, mapState: 'email'})
  email = '';

  @property({type: Boolean, store: homeProspectRegFormSdo, mapState: 'checked'})
  checked = false;

  @property({type: Object, store: homeProspectRegFormSdo, mapState: 'apiErrors'})
  apiErrors = null;

  @property({type: String, store: homeProspectRegFormSdo, mapState: 'languageLocale'})
  languageLocale;

  /**
   *
   * @private
   * @return {Object}
   */
  defineValidators() {
    return {
      email: {
        isRequired,
        isValidEmail,
        isNotAllDigits,
        isNotEmoji
      },
      checkbox: {
        isChecked: () => this.checked
      },
      apiErrors: {
        uncaught: uncaughtApiErrorsValidator()
      }
    };
  }

  /**
   *
   * @param {Event} e
   */
  async submitForm(e) {
    e.preventDefault();

    const prospectRegForm = this.shadowRoot.getElementById('prospect-register-form');
    const emailInput = this.shadowRoot.getElementById('email');
    if (prospectRegForm.disabled) return;

    this.clearApiErrors();

    this.form.validate();
    await this.form.validationComplete;
    if (!this.form.valid) {
      emailInput.focus();
      return;
    }

    prospectRegForm.disabled = true;
    ModalService.show('loaderIcon');

    const prospectRegFormPayload = this.createPayload();
    try {
      await ApiService.prospectRegisterEmail(prospectRegFormPayload);

      await PixelService.fire(pixelEvents.PROSPECT_REG);
      Cookies.set(cookies.ELOG, this.email, cookies.CONFIG);

      routerInstance.navigate(pages.SUBSCRIPTION, {});
    } catch (err) {
      prospectRegForm.disabled = false;
      try {
        apiErrorsEnumerate[err.payload.error.code](err, this);
      } catch (e) {
        apiErrorsEnumerate.defaultErrorHandler(err, this);
      }
    }
  }

  /**
   *
   * @param {Event} e
   */
  updateEmail(e) {
    this.clearApiErrors();
    this.email = e.target.value;
    homeProspectRegFormSdo.email = e.target.value;
  }

  /**
   *
   * @param {Event} e
   */
  validateEmail(e) {
    if (this.email.length !== 0) {
      this.form.email.isValidEmail.validate();
      this.form.email.isNotAllDigits.validate();
      this.form.email.isNotEmoji.validate();
    }
    if (this.form.email.observed) this.form.email.validate();
  }

  /**
   *
   * @param {Event} e
   */
  updateCheckbox(e) {
    this.clearApiErrors();
    this.checked = e.target.checked;
    homeProspectRegFormSdo.checked = e.target.checked;
    this.form.checkbox.validate();
  }

  /**
   *
   */
  createPayload() {
    return {
      aofl_product_uuid: aoflProducts.HOMESCHOOL_PRODUCT_UUID,
      email: this.email,
      prospectType: 'subscriber',
      languageLocale: this.isWdio ? 'en-US' : this.languageLocale,
      campaignSourceTag: Cookies.get(cookies.CAMPAIGN_INFO) || '',
      registrationType: 'mini_reg',
      subscriptionStatus: 'opted_in'
    };
  }

  /**
   *
   */
  clearApiErrors() {
    if (this.apiErrors) {
      homeProspectRegFormSdo.apiErrors = null;
      this.form.apiErrors.validate();
    }
  }

  /**
   *
   */
  goToSubscription(e) {
    e.preventDefault();
    return routerInstance.navigate(pages.SUBSCRIPTION, {});
  }

  /**
   *
   */
  disconnectedCallback() {
    super.disconnectedCallback();
    homeProspectRegFormSdo.reset();
  }

  /**
   *
   * @return {Object}
   */
  render() {
    return super.render(template, [styles]);
  }
}

export default HomeProspectRegForm;
