import {isMobile} from '@aoflmkt/is-mobile';
import {imageConfig} from './_config/images';

export default (ctx, html) => html`
  ${isMobile.any ? html`
    <button dom-scope="EaF7PKPl" id="menu-button" class="enteractive tab-outline-blue" type="button" @touchstart="${(e) => ctx.clickHandler(e)}" aria-expanded="${ctx.ddActive}" aria-label="Click to toggle menu button." tabindex="0">
      <img id="bar1" class="menu-icon bar-icon ${ctx.ddActive ? 'active': ''}" src="${imageConfig.horizontalBarBlue[0].src}" alt="">
      <img id="bar2" class="menu-icon bar-icon ${ctx.ddActive ? 'active': ''}" src="${imageConfig.horizontalBarBlue[0].src}" alt="">
      <img id="bar3" class="menu-icon bar-icon ${ctx.ddActive ? 'active': ''}" src="${imageConfig.horizontalBarBlue[0].src}" alt="">
      <img id="caret" class="menu-icon ${ctx.ddActive ? 'active': ''}" src="${imageConfig.caretBlue[0].src}" alt="">
    </button>
  ` : html`
    <button dom-scope="wdw45sJB" id="menu-button" class="enteractive tab-outline-yellow" type="button" alt="" @click="${(e) => ctx.clickHandler(e)}" aria-expanded="${ctx.ddActive}" aria-label="Click to toggle menu button." tabindex="0">
      <img id="bar1" class="menu-icon bar-icon ${ctx.ddActive ? 'active': ''}" src="${imageConfig.horizontalBarBlue[0].src}" alt="">
      <img id="bar2" class="menu-icon bar-icon ${ctx.ddActive ? 'active': ''}" src="${imageConfig.horizontalBarBlue[0].src}" alt="">
      <img id="bar3" class="menu-icon bar-icon ${ctx.ddActive ? 'active': ''}" src="${imageConfig.horizontalBarBlue[0].src}" alt="">
      <img id="caret" class="menu-icon ${ctx.ddActive ? 'active': ''}" src="${imageConfig.caretBlue[0].src}" alt="">
    </button>
  `}
`;
