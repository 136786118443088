import styles from './template.css';
import template from './template.js';
import {customElement, property} from '@aofl/element';
import {RegisterForm, registerFormSdo} from '@aoflmkt/register-form';
import {clickTrackerMixin} from '../click-tracker-mixin';
import {cookies} from '../constants-enumerate';
import {userInfoSdo} from '../sdo-user-info';
import Cookies from 'js-cookie';

/**
 * @summary HomeschoolRegisterForm
 * @class HomeschoolRegisterForm
 * @extends {AoflElement}
 */
@customElement('homeschool-register-form')
class HomeschoolRegisterForm extends clickTrackerMixin(RegisterForm) {
  /**
   *
   * Creates an instance of HomeschoolRegisterForm.
   */
  constructor() {
    super();
    this.userEmail = Cookies.get(cookies.ELOG) || userInfoSdo.email;
    registerFormSdo.email = Cookies.get(cookies.ELOG) || userInfoSdo.email;
  }

  /**
   * @readonly
   */
  static is = 'homeschool-register-form';

  @property({type: String})
  userEmail = Cookies.get(cookies.ELOG) || userInfoSdo.email;

  /**
   *
   * @return {Object}
   */
  render() {
    return super.render(template, [styles]);
  }
}

export default HomeschoolRegisterForm;
