import {Sdo, state, storeInstance} from '@aofl/store';

/**
 * @type {String}
 * @private
 */
const NAMESPACE = 'home-prospect-reg-form-sdo';

/**
 * @class HomeProspectRegFormSdo
 * @extends {Sdo}
 */
class HomeProspectRegFormSdo extends Sdo {
  /**
   * @static
   * @type {String}
   */
  static namespace = NAMESPACE;

  /**
   * @type {String}
   */
  @state()
  email = '';

  /**
   * @type {Boolean}
   */
  @state()
  checked = false;

  /**
   * @type {Object}
   */
  @state()
  apiErrors = null;

  /**
   * @type {Object}
   */
  @state()
  languageLocale = window.navigator.language ? window.navigator.language : 'en-US';
}

/**
 * @type {HomeProspectRegFormSdo}
 */
const homeProspectRegFormSdo = new HomeProspectRegFormSdo();
storeInstance.addState(homeProspectRegFormSdo);

export {
  homeProspectRegFormSdo
};
