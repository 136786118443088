import styles from './template.css';
import template from './template';
import {AoflElement, customElement, property} from '@aofl/element';
import {isMobile} from '@aoflmkt/is-mobile';
import {accessibilityMixin} from '../accessibility-mixin';
import {layoutSdo} from '../sdo-layout';

/**
 * @summary DdMenuButton
 * @extends {AoflElement}
 */
@customElement('dd-menu-button')
class DdMenuButton extends accessibilityMixin(AoflElement) {
  /**
   * Creates an instance of DdMenuButton.
   */
  constructor() {
    super();
    this.clickOrTouchEvent = (isMobile.any) ? 'touchstart' : 'click';
  }

  /**
   * @readonly
   */
  static is = 'dd-menu-button';

  @property({type: Boolean, attribute: false, store: layoutSdo, mapState: 'ddMenu'})
  ddActive = false;

  /**
   *
   * @param {Event} e
   */
  clickHandler(e) {
    if (e.cancelable) e.preventDefault();
    e.stopPropagation();

    // Handles IOS behavior where input fields don't lose focus when tapping outside of them
    this.shadowRoot.querySelector('#menu-button').focus();
    layoutSdo.ddMenu = !layoutSdo.ddMenu;
    layoutSdo.contentOverlay = !layoutSdo.contentOverlay;
    layoutSdo.footerOverlay = !layoutSdo.footerOverlay;
    layoutSdo.scroll = {
      freeze: true
    };
  }

  /**
   *
   */
  closeMenu() {
    layoutSdo.ddMenu = false;
    layoutSdo.contentOverlay = false;
    layoutSdo.footerOverlay = false;
    layoutSdo.scroll = {
      freeze: false
    };
  }

  /**
   *
   */
  connectedCallback() {
    super.connectedCallback();
    window.addEventListener(this.clickOrTouchEvent, this.closeMenu, {passive: true});
    window.addEventListener('keydown', (e) => {
      if (e.key === 'Escape') {
        this.closeMenu();
      }
    });
  }

  /**
   *
   */
  disconnectedCallback() {
    window.removeEventListener(this.clickOrTouchEvent, this.closeMenu, {passive: true});
    window.removeEventListener('keydown', (e) => {
      if (e.key === 'Escape') {
        this.closeMenu();
      }
    });
    super.disconnectedCallback();
  }

  /**
   *
   * @return {Object}
   */
  render() {
    return super.render(template, [styles]);
  }
}

export default DdMenuButton;
