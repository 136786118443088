import {Sdo, storeInstance, state} from '@aofl/store';

/**
* @type {String}
* @private
*/
const NAMESPACE = 'redeemCode';

/**
* @extends {Sdo}
*/
class RedeemSdo extends Sdo {
  /**
  * @static
  * @type {String}
  */
  static namespace = NAMESPACE;

  /**
  * @static
  * @type {String}
  */
  @state()
  redeemCode = '';

  /**
  * @static
  * @type {Boolean}
  */
  @state()
  invalidCode = false;

  /**
  * @static
  * @type {Object}
  */
  @state()
  apiErrors = null;

  /**
  * @static
  * @type {String}
  */
  @state()
  productHash = '';
}

/**
* @type {RedeemSdo}
*/
const redeemSdo = new RedeemSdo();
storeInstance.addState(redeemSdo);

export {
  redeemSdo
};
