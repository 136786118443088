import styles from './template.css';
import template from './template';
import {AoflElement, customElement, property} from '@aofl/element';
import {clickTrackerMixin} from '../click-tracker-mixin';
import {ModalService} from '../modal-service';
import {layoutSdo} from '../sdo-layout';

/**
 * @summary HomeschoolModal
 * @class HomeschoolModal
 * @extends {clickTrackerMixin(AoflElement)}
 */
@customElement('homeschool-modal')
class HomeschoolModal extends clickTrackerMixin(AoflElement) {
  /**
   * Creates an instance of HomeschoolModal.
   */
  constructor() {
    super();
    this.modalContainer = null;
    this.addEventListener('click', (e) => e.stopPropagation());
  }

  /**
   * @readonly
   */
  static is = 'homeschool-modal';

  /**
   * @return {Object}
   */
  @property({type: Boolean, attribute: 'prevent-close'})
  preventClose = false;

  /**
   *
   */
  connectedCallback() {
    super.connectedCallback();
    this.modalContainer = document.getElementById('simple-modal-container');
    if (!this.preventClose) {
      this.modalContainer.addEventListener('click', ModalService.hide);
    }
    setTimeout(() => window.dispatchEvent(new CustomEvent('resize')), 1000);
    layoutSdo.scrollFreeze(true);
  }

  /**
   *
   */
  disconnectedCallback() {
    super.disconnectedCallback();
    if (!this.preventClose) {
      this.modalContainer.removeEventListener('click', ModalService.hide);
    }
  }

  /**
   *
   * @return {Object}
   */
  render() {
    return super.render(template, [styles]);
  }
}

export default HomeschoolModal;
