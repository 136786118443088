import {isNotQualified} from '@aoflmkt/is-webp-qualified';

const imageConfig = {
  caretBlue: [{src: `${isNotQualified ? '/assets/templates/dd-menu-button/caret-blue-v1.svg' : '/assets/templates/dd-menu-button/caret-blue-v1.svg'}`}],
  horizontalBarBlue: [{src: `${isNotQualified ? '/assets/templates/dd-menu-button/horizontal-bar-blue-v1.svg' : '/assets/templates/dd-menu-button/horizontal-bar-blue-v1.svg'}`}]
};

export {
  imageConfig
};
