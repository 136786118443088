import styles from './template.css';
import template from './template.js';
import {customElement, property} from '@aofl/element';
import {PaymentForm, paymentFormSdo} from '@aoflmkt/payment-form';
import {isRequired} from '@aofl/form-validate';
import {isNotEmoji, isNotZero, isNotExpired} from '@aoflmkt/form-validators';
import {accessibilityMixin} from '../accessibility-mixin';
import {clickTrackerMixin} from '../click-tracker-mixin';
import {ModalService} from '../modal-service';
import {modalsConfig} from './__config/modals';

/**
 * @summary HomeschoolPaymentForm
 * @class HomeschoolPaymentForm
 * @extends {AoflElement}
 */
@customElement('homeschool-payment-form')
class HomeschoolPaymentForm extends clickTrackerMixin(accessibilityMixin(PaymentForm)) {
  /**
   *
   * Creates an instance of HomeschoolPaymentForm.
   */
  constructor() {
    super();
    ModalService.addModals(modalsConfig);
  }

  /**
   * @readonly
   */
  static is = 'homeschool-payment-form';

  @property({type: Boolean, attribute: true})
  ariaExpandedMonth = false;

  @property({type: Boolean, attribute: true})
  ariaExpandedYear = false;

  /**
   *
   * @return {Object}
   */
  defineValidators() {
    return {
      cardName: {isRequired, isNotEmoji},
      cardNumber: {isRequired, isNotZero},
      cardMonth: {isRequired},
      cardYear: {isRequired},
      cardCvv: {isRequired, isNotZero, isNotEmoji},
      expDate: {isNotExpired: () => isNotExpired(paymentFormSdo.expDate)},
      zipCode: {isRequired, isNotZero, isNotEmoji},
    };
  }

  /**
   *
   */
  showCvvPopup() {
    ModalService.show('cvvCodeModal');
  }

  /**
   * @param {Event} e
   */
  toggleMonth(e) {
    super.toggleMonth(e);
    this.ariaExpandedMonth = !this.ariaExpandedMonth;
  }

  /**
   * @param {Event} e
   */
  updateCardMonth(e) {
    super.updateCardMonth(e);
    this.ariaExpandedMonth = !this.ariaExpandedMonth;
  }

  /**
   * @param {Event} e
   */
  toggleYear(e) {
    super.toggleYear(e);
    this.ariaExpandedYear = !this.ariaExpandedYear;
  }

  /**
   * @param {Event} e
   */
  updateCardYear(e) {
    super.updateCardYear(e);
    this.ariaExpandedYear = !this.ariaExpandedYear;
  }

  /**
   *
   */
  async connectedCallback() {
    super.connectedCallback();

    await this.updateComplete;
    this.enteractiveElements = this.shadowRoot.querySelectorAll('.enteractive-list-option');
    if (!this.enteractiveElements) return;

    this.enteractiveElements.forEach((elem) => {
      elem.addEventListener('keydown', (e) => {
        if (e.keyCode === 13 || e.keyCode === 32) {
          elem.click();
        }
      });
    });
  }

  /**
   *
   * @return {Object}
   */
  render() {
    return super.render(template, [styles]);
  }
}

export default HomeschoolPaymentForm;
