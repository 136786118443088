export default (ctx, html) => html`
  <form id="register-form-container" name="register-form" @submit="${(e) => ctx.submitForm(e)}" novalidate>
    <fieldset>
      <div class="input-container ${ctx.form.email.valid}">
        <label for="email">Email</label>
        <div class="input-wrapper">
          <input id="email" class="input ${ctx.form.email.valid ? '' : 'invalid'}" type="email" name="email" @blur="${(e) => ctx.validateEmail(e)}" @input="${(e) => ctx.updateEmail(e)}" value="${ctx.email}" autocomplete="email" aria-label="Enter your email address." aria-describedby="email-error-message" aria-invalid="${ctx.form.email.valid ? 'false' : 'true'}" required>
          ${ctx.form.email.isRequired.valid ? '' : html`
            <label id="email-error-message" class="error-message" for="email" role="alert status" aria-label="Error - Please enter an email address. It is a required field and must be filled in." aria-live="polite">Please enter an email address.</label>
          `}
          ${ctx.form.email.isValidEmail.valid && ctx.form.email.isNotEmoji.valid ? '' : html`
            <label id="email-error-message" class="error-message" for="email" role="alert status" aria-label="Error - Please enter a valid email address. It is a required field and must be filled in." aria-live="polite">Email format you entered is invalid.</label>
          `}
        </div>
      </div>
      <div class="input-container ${ctx.form.confirmEmail.valid}">
        <label for="confirm-email">Confirm<span class="u-br"></span><span class="u-m-br"></span>Email</label>
        <div class="input-wrapper">
          <input id="confirm-email" class="input ${ctx.form.confirmEmail.valid ? '' : 'invalid'}" type="email" name="confirm-email" @blur="${(e) => ctx.validateConfirmEmail(e)}" onpaste="return false" @input="${(e) => ctx.updateConfirmEmail(e)}" value="${ctx.confirmEmail}" autocomplete="email" aria-label="Confirm your email address." aria-describedby="confirm-email-error-message" aria-invalid="${ctx.form.confirmEmail.valid ? 'false' : 'true'}" required>
          ${ctx.form.confirmEmail.isRequired.valid ? '' : html`
            <label id="confirm-email-error-message" class="error-message" for="confirm-email" role="alert status" aria-label="Error - Please confirm your email address. It is a required field and must be filled in." aria-live="polite">Please confirm your email.</label>
          `}
          ${ctx.form.confirmEmail.isEqual.valid ? '' : html`
            <label id="confirm-email-error-message" class="error-message" for="confirm-email" role="alert status" aria-label="Error -  Email addresses do not match. It is a required field and must be filled in." aria-live="polite">Email addresses do not match.</label>
          `}
        </div>
      </div>
      <div class="input-container ${ctx.form.password.valid}">
        <label for="password">Password</label>
        <div class="input-wrapper">
          <input id="password" class="input ${ctx.form.password.valid ? '' : 'invalid'}" type="password" name="password" @blur="${(e) => ctx.validatePassword(e)}" @input="${(e) => ctx.updatePassword(e)}" value="${ctx.password}" autocomplete="new-password" aria-label="Enter your password." aria-describedby="password-error-message" aria-invalid="${ctx.form.password.valid ? 'false' : 'true'}" required>
          ${ctx.form.password.isRequired.valid ? '' : html`
            <label id="password-error-message" class="error-message" for="password" role="alert status" aria-label="Error - Please enter a password. It is a required field and must be filled in." aria-live="polite">Please enter a password.</label>
          `}
          ${ctx.form.password.minLength.valid ? '' : html`
            <label id="password-error-message" class="error-message" for="password" role="alert status" aria-label="Error - Password must be longer than 5 characters. It is a required field and must be filled in." aria-live="polite">Password must be longer than 5 characters.</label>
          `}
          ${ctx.form.password.isNotEmail.valid ? '' : html`
            <label id="password-error-message" class="error-message" for="password" role="alert status" aria-label="Error - Password cannot be an email address. It is a required field and must be filled in." aria-live="polite">Password can't be an email address.</label>
          `}
          ${ctx.form.password.isNotAllDigits.valid ? '' : html`
            <label id="password-error-message" class="error-message" for="password" role="alert status" aria-label="Error - Password cannot consist of only digits. It is a required field and must be filled in." aria-live="polite">Password can't have only digits.</label>
          `}
        </div>
      </div>
      <div id="last-input-container" class="input-container ${ctx.form.confirmPassword.valid}">
        <label for="confirm-password">Confirm Password</label>
        <div class="input-wrapper">
          <input id="confirm-password" class="input ${ctx.form.confirmPassword.valid ? '' : 'invalid'}" type="password" name="confirm-password" @blur="${(e) => ctx.validateConfirmPassword(e)}" @input="${(e) => ctx.updateConfirmPassword(e)}" onpaste="return false;" value="${ctx.confirmPassword}" autocomplete="new-password" aria-label="Confirm your password." aria-describedby="confirm-password-error-message" aria-invalid="${ctx.form.confirmPassword.valid ? 'false' : 'true'}" required>
          ${ctx.form.confirmPassword.isRequired.valid ? '' : html`
            <label id="confirm-password-error-message" class="error-message" for="confirm-password" role="alert status" aria-label="Error - Please confirm your password. It is a required field and must be filled in." aria-live="polite">Please confirm your password.</label>
          `}
          ${ctx.form.confirmPassword.isEqual.valid ? '' : html`
            <label id="confirm-password-error-message" class="error-message" for="confirm-password" role="alert status" aria-label="Error - Passwords do not match. It is a required field and must be filled in." aria-live="polite">Passwords do not match.</label>
          `}
        </div>
      </div>
    </fieldset>
    <button dom-scope="tZJXDmjR" id="register-submit-button" type="submit" tabindex="-1">Register Submit Button</button>
  </form>
`;
