import {Sdo, storeInstance, state} from '@aofl/store';
import {cookies} from '../../../../../modules/constants-enumerate';
import Cookies from 'js-cookie';

/**
 * @type {String}
 * @private
 */
const NAMESPACE = 'home-page';

/**
 * @extends {Sdo}
 */
class HomePageSdo extends Sdo {
  /**
   * @static
   * @type {String}
   */
  static namespace = NAMESPACE;

  /**
   * @static
   * @type {String}
   */
  @state()
  isWdio = Cookies.get(cookies.WDIO) ? 'wdio' : '';

  /**
   * @static
   * @type {Boolean}
   */
  @state()
  showLazyLoadSection = false;
}

/**
 * @type {HomePageSdo}
 * @memberof module:@aofladmin/prospect-reg-form-sdo
 */
const homePageSdo = new HomePageSdo();
storeInstance.addState(homePageSdo);

export {
  homePageSdo
};
